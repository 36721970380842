import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { ToastContainer, Flip } from 'react-toastify';
import { ApplicationRoot } from './application/ApplicationRoot';
import { DisclaimerModal } from './general/DisclaimerModal';
import { EligibilityDisclaimerModal } from './general/EligibilityDisclaimerModal';
import { CaseModal } from './general/CaseModal';
import { IneligibleCasesModal } from './general/IneligibleCasesModal';
import { EmailResultsModal } from './general/EmailResultsModal';
import { SurveyModal } from './general/SurveyModal';
import { Login } from './admin/Login';
import { EmailsRoot } from './emails/EmailsRoot';
import { EmailKnowYourRightsModal } from './general/EmailKnowYourRightsModal';
import {
  VetsReemploymentPrgModal,
  VetsTreatmentCourtModal,
} from './general/QuestionAssistModals';
import { NoOrgFound } from './general/NoOrgFound';
import { AdminRoot } from './admin/AdminRoot';
import { NewUserModal } from './general/NewUserModal';
import { FollowupCaseModal } from './general/FollowupCaseModal';
import { ExportModal } from './general/ExportModal';
import { ConfirmationPage } from './general/ConfirmationPage';
import { ResetPassword } from './admin/ResetPassword';
import { Footer } from './general/Footer';
import { HelpModal } from './general/HelpModal';

// Highest-level routes for the application
// Also provides modals and toast (little confirmation popups) used throughout the app
export const RootRoutes = () => {
  return (
    <>
      <ToastContainer
        autoClose={3000}
        position="bottom-right"
        transition={Flip}
        hideProgressBar
      />
      <Routes>
        <Route path=":orgSlug/*" element={<OrgRoutes />} />
        <Route path="/" element={<NoOrgFound />} />
      </Routes>
    </>
  );
};

// High-level routes for the application
export const OrgRoutes = () => {
  return (
    <>
      <IneligibleCasesModal />
      <CaseModal />
      <DisclaimerModal />
      <EligibilityDisclaimerModal />
      <EmailResultsModal />
      <EmailKnowYourRightsModal />
      <SurveyModal />
      <VetsTreatmentCourtModal />
      <VetsReemploymentPrgModal />
      <NewUserModal />
      <FollowupCaseModal />
      <ExportModal />
      <HelpModal />
      <Routes>
        <Route path="app/*" element={<ApplicationRoot />} />
        <Route path="confirmation" element={<ConfirmationPage />} />
        <Route path="emails/*" element={<EmailsRoot />} />
        <Route path="login" element={<Login />} />
        <Route
          path="reset-password/:resetPasswordToken"
          element={<ResetPassword />}
        />
        <Route path="admin/*" element={<AdminRoot />} />
        <Route index element={<Navigate to="app" replace />} />
      </Routes>
    </>
  );
};