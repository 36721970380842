import React, { useEffect } from 'react';
import { Beforeunload } from 'react-beforeunload';
import { useSelector } from 'react-redux';
import {
  Navigate,
  Route,
  Routes,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { useRequest } from 'redux-query-react';
import { getConfigQuery } from '../../actions/queries';
import { useColors } from '../../hooks/useColors';
import { searchValuesSelector, tokenSelector } from '../../selectors/entities';
import { agreeToTermsSelector } from '../../selectors/general';
import { statusIsGood } from '../../utils/helpers';
import { Header } from '../general/Header';
import { PageLoader } from '../general/Loaders';
import TokenExpirationTimer from '../general/TokenExpirationTimer';
import { CaseFollowupRoutes } from './CaseFollowups';
import { ConfirmCases } from './ConfirmCases';
import { PrelimQuestions } from './CriminalHistory';
import { Expenses } from './Expenses';
import { IncomeAndAssets } from './IncomeAndAssets';
import { NoCasesFoundPage } from './NoCasesFound';
import { PersonalInfo } from './PersonalInfo';
import { Results } from './Results';
import { Review } from './Review';
import { TermsAndConditions } from './TermsAndConditions';

// Root component for all of the "Next steps" pages
export const ApplicationRoot = () => {
  const { bgColor } = useColors();
  const { orgSlug } = useParams();
  const navigate = useNavigate();
  const [{ isFinished, status }] = useRequest(getConfigQuery(orgSlug));
  const appHasBeenStarted = useSelector(searchValuesSelector) != null;
  const agreeToTerms = useSelector(agreeToTermsSelector);
  const showRefreshWarning = useSelector(tokenSelector) != null;

  useEffect(() => {
    // Redirect to the 404 page if no org is found
    if (isFinished && !statusIsGood(status)) {
      navigate('/');
    }
  }, [isFinished, status, navigate]);

  if (!isFinished) {
    return <PageLoader />;
  }

  return (
    <div style={{ minHeight: '100vh', textAlign: 'center' }}>
      {showRefreshWarning && (
        <Beforeunload onBeforeunload={(event) => event.preventDefault()} />
      )}
      <Header title="Application" />
      <Routes>
        <Route path="terms" element={<TermsAndConditions />} />
        {agreeToTerms && (
          <>
            <Route path="personalInfo" element={<PersonalInfo />} />
            {appHasBeenStarted && (
              <>
                <Route
                  path="personalInfo/confirmCases"
                  element={<ConfirmCases />}
                />
                <Route
                  path="personalInfo/noCasesFound"
                  element={<NoCasesFoundPage />}
                />
                <Route path="incomeAndAssets" element={<IncomeAndAssets />} />
                <Route path="expenses" element={<Expenses />} />
                <Route
                  path="criminalHistory/followups/*"
                  element={<CaseFollowupRoutes />}
                />
                <Route path="criminalHistory" element={<PrelimQuestions />} />
                <Route path="eligibility" element={<Results />} />
                <Route path="review" element={<Review />} />
              </>
            )}
          </>
        )}
        <Route index element={<Navigate to="terms" replace />} />
      </Routes>
      <TokenExpirationTimer />
    </div>
  );
};
