import { useDispatch, useSelector } from 'react-redux';
import { mutateAsync } from 'redux-query';
import { toast } from 'react-toastify';
import { useState } from 'react';
import {
  caseFollowupQuery,
  checkEligibilityQuery,
  additionalInfoQuery,
  confirmCasesQuery,
  feedbackSurveyQuery,
  sendCaseResultsEmailQuery,
  sendKnowYourRightsQuery,
  updateConfigQuery,
  loginQuery,
  updateIncomeAndAssetsQuery,
  updateExpensesQuery,
  addUserQuery,
  deleteUserQuery,
  submitApplicationQuery,
  quickLookupQuery,
  addLogoQuery,
  getExportAllQuery,
  getExportNewQuery,
  getExportPrevQuery,
  markAsExportedQuery,
  quickLookupBySPNQuery,
  forgotPasswordQuery,
  resetPasswordQuery,
} from '../actions/queries';
import { statusIsGood, statusIsUnauthorized } from '../utils/helpers';
import { useNavigate, useParams } from 'react-router-dom';
import { tokenSelector } from '../selectors/entities';
import { useHandleUnauthorized } from './useHandleUnauthorized';
import { useAdjacentSteps } from './useSteps';
import {
  SESSION_EXP_STORAGE_KEY,
  EXPORT_ALL,
  EXPORT_PREV,
} from '../constants/general';
import {
  appExportColumns,
  caseExportColumns,
  generateCsv,
} from '../utils/json2csv';
import { setExportModal } from '../actions/modals';

// Returns common vars/functions used across editors
const useEditorBasics = () => {
  const dispatch = useDispatch();
  const jwtToken = useSelector(tokenSelector);
  const handleUnauthorized = useHandleUnauthorized();
  return [dispatch, jwtToken, handleUnauthorized];
};

export const useInitialForm = (onSuccess) => {
  const [submitting, setSubmitting] = useState(false);
  const [dispatch, jwtToken] = useEditorBasics();
  const { orgSlug } = useParams();

  const submit = (values) => {
    setSubmitting(true);
    // remove any blank ("") names from the aliases arrays
    const filteredValues = {
      ...values,
      aliasFirstNames: values.aliasFirstNames.filter((name) => name),
      aliasLastNames: values.aliasLastNames.filter((name) => name),
      // not sure if this is the best place to set agreeToTerms? my assumption is that the terms page is always required, so if they get to personalInfo, they have already agreed; if it's not, we should probably set this based on if the configuration is set to require that page
      agreedToTerms: true,
      orgSlug,
    };
    dispatch(
      mutateAsync(checkEligibilityQuery(filteredValues, jwtToken, orgSlug))
    ).then(({ status, body }) => {
      if (statusIsGood(status) && body) {
        setSubmitting(false);
        const cases = body.eligibilityInfo ? body.eligibilityInfo.cases : null;
        const casesFound = Array.isArray(cases) && cases.length > 0;
        onSuccess(casesFound);
      } else {
        setSubmitting(false);
        toast.error(
          body && body.msg ? body.msg : 'Could not get eligibility info'
        );
      }
    });
  };
  return [submit, submitting];
};

export const useConfirmCases = () => {
  const navigate = useNavigate();
  const { nextStep } = useAdjacentSteps('personalInfo');
  const [dispatch, jwtToken, handleUnauthorized] = useEditorBasics();

  const submit = (spn) => {
    dispatch(mutateAsync(confirmCasesQuery(spn, jwtToken))).then(
      ({ status, body }) => {
        if (statusIsGood(status) && body) {
          navigate(nextStep);
        } else if (statusIsUnauthorized(status)) {
          handleUnauthorized();
        } else {
          toast.error(
            body && body.msg ? body.msg : 'Could not confirm case selection'
          );
        }
      }
    );
  };
  return [submit];
};

// Returns disqualifying offenses, sex offender, veteran, & human trafficking victim question answers
export const useAdditionalInfo = (onSuccess) => {
  const [submitting, setSubmitting] = useState(false);
  const [dispatch, jwtToken, handleUnauthorized] = useEditorBasics();

  const submit = (values) => {
    setSubmitting(true);
    dispatch(mutateAsync(additionalInfoQuery(values, jwtToken))).then(
      ({ status, body }) => {
        if (statusIsGood(status) && body) {
          setSubmitting(false);
          onSuccess();
        } else if (statusIsUnauthorized(status)) {
          handleUnauthorized();
        } else {
          setSubmitting(false);
          toast.error(
            body && body.msg
              ? body.msg
              : 'Could not save additional information'
          );
        }
      }
    );
  };
  return [submit, submitting];
};

// Return followup questions for a specific case
export const useCaseFollowupQuestions = (onSuccess, currCase) => {
  const [submitting, setSubmitting] = useState(false);
  const [dispatch, jwtToken, handleUnauthorized] = useEditorBasics();

  const submit = (values) => {
    setSubmitting(true);
    // Return form values and the case object's mongodb ID
    const formValuesWithId = {
      ...values,
      _id: currCase._id,
    };
    dispatch(mutateAsync(caseFollowupQuery(formValuesWithId, jwtToken))).then(
      ({ status, body }) => {
        if (statusIsGood(status) && body) {
          setSubmitting(false);
          onSuccess();
        } else if (statusIsUnauthorized(status)) {
          handleUnauthorized();
        } else {
          setSubmitting(false);
          toast.error(
            body && body.msg ? body.msg : 'Could not save case information'
          );
        }
      }
    );
  };
  return [submit, submitting];
};

// Submit feedback survey results
export const useFeedbackSurvey = (onSuccess) => {
  const [submitting, setSubmitting] = useState(false);
  const [dispatch, jwtToken, handleUnauthorized] = useEditorBasics();

  const submit = (values) => {
    setSubmitting(true);
    dispatch(mutateAsync(feedbackSurveyQuery(values, jwtToken))).then(
      ({ status, body }) => {
        if (statusIsGood(status) && body) {
          setSubmitting(false);
          toast.success(
            body && body.msg ? body.msg : 'Submitted feedbacks, thanks!'
          );
          onSuccess();
        } else if (statusIsUnauthorized(status)) {
          handleUnauthorized();
        } else {
          setSubmitting(false);
          toast.error(body && body.msg ? body.msg : 'Could not submit survey');
        }
      }
    );
  };
  return [submit, submitting];
};

// User update to income and assets
export const useUpdateIncomeAndAssets = (onSuccess) => {
  const [submitting, setSubmitting] = useState(false);
  const [dispatch, jwtToken, handleUnauthorized] = useEditorBasics();

  const submit = (values) => {
    setSubmitting(true);
    // don't send any empty dependents
    const filteredValues = {
      ...values,
      dependents: values.dependents.filter(
        (dep) => dep.age != '' && dep.name != '' && dep.relationship != ''
      ),
    };
    dispatch(
      mutateAsync(updateIncomeAndAssetsQuery(filteredValues, jwtToken))
    ).then(({ status, body }) => {
      if (statusIsGood(status) && body) {
        setSubmitting(false);
        toast.success(
          body && body.msg
            ? body.msg
            : 'Income and assets updated successfully!'
        );
        onSuccess();
      } else if (statusIsUnauthorized(status)) {
        handleUnauthorized();
      } else {
        setSubmitting(false);
        toast.error(
          body && body.msg ? body.msg : 'Failed to update income and assets.'
        );
      }
    });
  };
  return [submit, submitting];
};

// User update to expenses
export const useUpdateExpenses = (onSuccess) => {
  const [submitting, setSubmitting] = useState(false);
  const [dispatch, jwtToken, handleUnauthorized] = useEditorBasics();

  const submit = (values) => {
    setSubmitting(true);
    dispatch(mutateAsync(updateExpensesQuery(values, jwtToken))).then(
      ({ status, body }) => {
        if (statusIsGood(status) && body) {
          setSubmitting(false);
          toast.success(
            body && body.msg ? body.msg : 'Expenses updated successfully!'
          );
          onSuccess();
        } else if (statusIsUnauthorized(status)) {
          handleUnauthorized();
        } else {
          setSubmitting(false);
          toast.error(
            body && body.msg ? body.msg : 'Failed to update expenses'
          );
        }
      }
    );
  };
  return [submit, submitting];
};

// User submit on review page
export const useSubmitApplication = (onSuccess) => {
  const [submitting, setSubmitting] = useState(false);
  const [dispatch, jwtToken, handleUnauthorized] = useEditorBasics();

  const submit = () => {
    setSubmitting(true);
    // hard code agree and submit values for now; could potentially pass values from form at later point if desired
    const values = {
      agreedAndSubmitted: true,
      dateSubmitted: Date.now(),
    };
    dispatch(mutateAsync(submitApplicationQuery(values, jwtToken))).then(
      ({ status, body }) => {
        if (statusIsGood(status) && body) {
          setSubmitting(false);
          onSuccess();
        } else if (statusIsUnauthorized(status)) {
          handleUnauthorized();
        } else {
          setSubmitting(false);
          toast.error(
            body && body.msg ? body.msg : 'Failed to submit application'
          );
        }
      }
    );
  };
  return [submit, submitting];
};

// Email case results to user
export const useSendCaseResultsEmail = (onSuccess) => {
  const [submitting, setSubmitting] = useState(false);
  const [dispatch, jwtToken, handleUnauthorized] = useEditorBasics();
  const { orgSlug } = useParams();

  const submit = ({ email }) => {
    setSubmitting(true);
    dispatch(
      mutateAsync(sendCaseResultsEmailQuery(email, jwtToken, orgSlug))
    ).then(({ status, body }) => {
      if (statusIsGood(status) && body) {
        setSubmitting(false);
        // TODO: maybe say again what email it send results to
        toast.success(
          body && body.msg ? body.msg : 'Email sent with your case results!'
        );
        onSuccess();
      } else if (statusIsUnauthorized(status)) {
        handleUnauthorized();
      } else {
        setSubmitting(false);
        toast.error(body && body.msg ? body.msg : 'Could not send email');
      }
    });
  };
  return [submit, submitting];
};

// Email Know Your Rights information to user
export const useSendKnowYourRightsEmail = (onSuccess) => {
  const [submitting, setSubmitting] = useState(false);
  const [dispatch, jwtToken, handleUnauthorized] = useEditorBasics();
  const { orgSlug } = useParams();

  const submit = ({ email }) => {
    setSubmitting(true);
    dispatch(
      mutateAsync(sendKnowYourRightsQuery(email, jwtToken, orgSlug))
    ).then(({ status, body }) => {
      if (statusIsGood(status) && body) {
        setSubmitting(false);
        toast.success(body && body.msg ? body.msg : 'Email sent!');
        onSuccess();
      } else if (statusIsUnauthorized(status)) {
        handleUnauthorized();
      } else {
        setSubmitting(false);
        toast.error(body && body.msg ? body.msg : 'Could not send email');
      }
    });
  };
  return [submit, submitting];
};

// Admin login query
export const useLoginQuery = (onSuccess) => {
  const [submitting, setSubmitting] = useState(false);
  const { orgSlug } = useParams();
  const [dispatch] = useEditorBasics();

  const submit = (values) => {
    values.username = values.email;
    values.orgSlug = orgSlug;
    setSubmitting(true);
    dispatch(mutateAsync(loginQuery(values, orgSlug))).then(
      ({ status, body }) => {
        if (statusIsGood(status) && body) {
          setSubmitting(false);
          const sessionExpDate = new Date(body.expirationTime);
          const sessionExpTimeMs = sessionExpDate.getTime();
          localStorage.setItem(SESSION_EXP_STORAGE_KEY, sessionExpTimeMs);
          toast.success(body && body.msg ? body.msg : 'Login successful!');
          onSuccess();
        } else {
          setSubmitting(false);
          toast.error(
            body && body.msg
              ? body.msg
              : 'Could not log in with the provided credentials'
          );
        }
      }
    );
  };
  return [submit, submitting];
};

// Admin update to add a user
export const useAddUser = (onSuccess) => {
  const [submitting, setSubmitting] = useState(false);
  const [dispatch, , handleUnauthorized] = useEditorBasics();
  const { orgSlug } = useParams();

  const submit = (values) => {
    setSubmitting(true);
    dispatch(
      mutateAsync(addUserQuery({ ...values, username: values.email }, orgSlug))
    ).then(({ status, body }) => {
      if (statusIsGood(status) && body) {
        setSubmitting(false);
        toast.success(body && body.msg ? body.msg : 'Added user successfully!');
        onSuccess();
      } else if (statusIsUnauthorized(status)) {
        handleUnauthorized();
      } else {
        setSubmitting(false);
        toast.error(body && body.msg ? body.msg : 'Failed to add user');
      }
    });
  };
  return [submit, submitting];
};

// Admin update to delete a user
export const useDeleteUser = () => {
  const [submitting, setSubmitting] = useState(false);
  const [dispatch, , handleUnauthorized] = useEditorBasics();
  const { orgSlug } = useParams();

  const submit = (userId) => {
    setSubmitting(true);
    dispatch(mutateAsync(deleteUserQuery(userId, orgSlug))).then(
      ({ status, body }) => {
        if (statusIsGood(status) && body) {
          setSubmitting(false);
          toast.success(
            body && body.msg ? body.msg : 'Deleted user successfully!'
          );
        } else if (statusIsUnauthorized(status)) {
          handleUnauthorized();
        } else {
          setSubmitting(false);
          toast.error(body && body.msg ? body.msg : 'Failed to delete user');
        }
      }
    );
  };
  return [submit, submitting];
};

// Admin forgot password
export const useForgotPasswordQuery = () => {
  const [submitting, setSubmitting] = useState(false);
  const [dispatch] = useEditorBasics();
  const { orgSlug } = useParams();

  const submit = (values) => {
    setSubmitting(true);
    dispatch(mutateAsync(forgotPasswordQuery(values.email, orgSlug))).then(
      ({ status, body }) => {
        if (statusIsGood(status) && body) {
          setSubmitting(false);
          toast.success(
            body && body.msg
              ? body.msg
              : 'Success! Check your inbox for instructions.'
          );
        } else {
          setSubmitting(false);
          toast.error(
            body && body.msg ? body.msg : 'Could not reset password.'
          );
        }
      }
    );
  };
  return [submit, submitting];
};

// Admin reset password
export const useResetPasswordQuery = (onSuccess, token) => {
  const [submitting, setSubmitting] = useState(false);
  const [dispatch] = useEditorBasics();
  const { orgSlug } = useParams();

  const submit = (values) => {
    setSubmitting(true);
    dispatch(
      mutateAsync(resetPasswordQuery(values.password, token, orgSlug))
    ).then(({ status, body }) => {
      if (statusIsGood(status) && body) {
        setSubmitting(false);
        toast.success(
          body && body.msg ? body.msg : 'Successfully updated password!'
        );
        onSuccess();
      } else {
        setSubmitting(false);
        toast.error(body && body.msg ? body.msg : 'Could not reset password.');
      }
    });
  };
  return [submit, submitting];
};

// Admin update application question config query
export const useConfigUpdateQuery = () => {
  const [submitting, setSubmitting] = useState(false);
  const [dispatch, , handleUnauthorized] = useEditorBasics();
  const { orgSlug } = useParams();

  const submit = (values) => {
    setSubmitting(true);
    dispatch(mutateAsync(updateConfigQuery(values, orgSlug))).then(
      ({ status, body }) => {
        if (statusIsGood(status) && body) {
          setSubmitting(false);
          toast.success(
            body && body.msg
              ? body.msg
              : 'Application questions update successful!'
          );
        } else if (statusIsUnauthorized(status)) {
          handleUnauthorized();
        } else {
          setSubmitting(false);
          toast.error(
            body && body.msg
              ? body.msg
              : 'Could not update the application questions.'
          );
        }
      }
    );
  };
  return [submit, submitting];
};

export const useQuickLookup = (onSuccess) => {
  const [submitting, setSubmitting] = useState(false);
  const [dispatch] = useEditorBasics();
  const { orgSlug } = useParams();

  const submit = (values) => {
    setSubmitting(true);
    const filteredValues = {
      ...values,
      aliasFirstNames: values.aliasFirstNames.filter((name) => name),
      aliasLastNames: values.aliasLastNames.filter((name) => name),
      quickLookup: true,
    };
    dispatch(mutateAsync(quickLookupQuery(filteredValues, orgSlug))).then(
      ({ status, body }) => {
        if (statusIsGood(status) && body) {
          setSubmitting(false);
          const cases = body.eligibilityInfo
            ? body.eligibilityInfo.cases
            : null;
          const casesFound = Array.isArray(cases) && cases.length > 0;
          onSuccess(casesFound);
        } else {
          setSubmitting(false);
          toast.error(
            body && body.msg ? body.msg : 'Could not get eligibility info'
          );
        }
      }
    );
  };
  return [submit, submitting];
};

export const useQuickLookupBySPN = (onSuccess) => {
  const [submitting, setSubmitting] = useState(false);
  const [dispatch] = useEditorBasics();
  const { orgSlug } = useParams();

  const submit = (values) => {
    setSubmitting(true);
    dispatch(mutateAsync(quickLookupBySPNQuery(values, orgSlug))).then(
      ({ status, body }) => {
        if (statusIsGood(status) && body) {
          setSubmitting(false);
          const cases = body.eligibilityInfo
            ? body.eligibilityInfo.cases
            : null;
          const casesFound = Array.isArray(cases) && cases.length > 0;
          onSuccess(casesFound);
        } else {
          setSubmitting(false);
          toast.error(
            body && body.msg ? body.msg : 'Could not get eligibility info'
          );
        }
      }
    );
  };
  return [submit, submitting];
};

const convertToCSVAndDownload = (jsonObj, exportType, isAppObj) => {
  if (!jsonObj) {
    return;
  }
  const csv = generateCsv(
    isAppObj ? appExportColumns : caseExportColumns,
    jsonObj
  );
  var exportedFilename = `${exportType}_${
    isAppObj ? 'application' : 'case'
  }s_export.csv`;
  var blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
  if (navigator.msSaveBlob) {
    // IE 10+
    navigator.msSaveBlob(blob, exportedFilename);
  } else {
    var link = document.createElement('a');
    if (link.download !== undefined) {
      // feature detection
      // Browsers that support HTML5 download attribute
      var url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', exportedFilename);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
};

// exportType: EXPORT_ALL, EXPORT_PREV, EXPORT_NEW
export const useExport = (exportType) => {
  const [submitting, setSubmitting] = useState(false);
  const [dispatch] = useEditorBasics();
  const { orgSlug } = useParams();

  const submit = () => {
    setSubmitting(true);
    const query =
      exportType === EXPORT_ALL
        ? getExportAllQuery
        : exportType === EXPORT_PREV
        ? getExportPrevQuery
        : getExportNewQuery;
    dispatch(mutateAsync(query(orgSlug))).then(({ status, body }) => {
      if (statusIsGood(status)) {
        setSubmitting(false);
        convertToCSVAndDownload(body.appExport, exportType, true);
        convertToCSVAndDownload(body.caseExport, exportType, false);
        if (exportType !== EXPORT_PREV && body.appExport != null) {
          dispatch(setExportModal(exportType));
        } else {
          toast.success(body && body.msg ? body.msg : 'Export successful!');
        }
      } else {
        setSubmitting(false);
        toast.error(body && body.msg ? body.msg : 'Could not get export');
      }
    });
  };
  return [submit, submitting];
};

export const useMarkAsExported = (onSuccess) => {
  const [submitting, setSubmitting] = useState(false);
  const [dispatch] = useEditorBasics();
  const { orgSlug } = useParams();

  const submit = () => {
    setSubmitting(true);
    dispatch(mutateAsync(markAsExportedQuery(orgSlug))).then(
      ({ status, body }) => {
        if (statusIsGood(status)) {
          setSubmitting(false);
          toast.success(body && body.msg ? body.msg : 'Marked as exported!');
          onSuccess();
        } else {
          setSubmitting(false);
          toast.error(
            body && body.msg ? body.msg : 'Could not mark as exported'
          );
        }
      }
    );
  };
  return [submit, submitting];
};

// Admin update image
export const useImageUploadQuery = (onSuccess) => {
  const [submitting, setSubmitting] = useState(false);
  const [dispatch, , handleUnauthorized] = useEditorBasics();
  const { orgSlug } = useParams();

  const submit = (imgFile) => {
    setSubmitting(true);
    // convert values to FormData format
    let formData = new FormData();
    formData.append('logo', imgFile);
    dispatch(mutateAsync(addLogoQuery(formData, orgSlug))).then(
      ({ status, body }) => {
        if (statusIsGood(status) && body) {
          setSubmitting(false);
          toast.success(body && body.msg ? body.msg : 'Logo saved!');
          onSuccess && onSuccess();
        } else if (statusIsUnauthorized(status)) {
          handleUnauthorized();
        } else {
          setSubmitting(false);
          toast.error(body && body.msg ? body.msg : 'Could not save logo');
        }
      }
    );
  };
  return [submit, submitting];
};
