import React from 'react';
import styled from 'styled-components';
import { useNavigate, useParams } from 'react-router-dom';
import { Formik } from 'formik';
import { Button } from '../general/Common';
import { BeigeTile, Header } from '../general/BeigeTile.js';
import { appConfigSelector } from '../../selectors/entities';
import { useDispatch, useSelector } from 'react-redux';
import { agreeToTerms } from '../../actions/general';
import { CheckboxField } from '../general/FormFields';
import { useColors } from '../../hooks/useColors';

const Text = styled.p`
  margin: 10px 0px;
`;

const TermsWrapper = styled.div`
  border-left: 4px solid ${({ bordercolor }) => bordercolor};
  background-color: ${({ bgcolor }) => bgcolor};
  padding: 15px;
  margin: 15px 0px;
  padding-left: 20px;
  border-radius: 4px;
  white-space: pre-line;
`;

// First form users sees in the eligibility checker flow, asks for name(s) and DOB
export const TermsAndConditions = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { orgSlug } = useParams();
  const appConfig = useSelector(appConfigSelector);
  const { bgDarker, bgLighter } = useColors();

  return (
    <Formik
      initialValues={{
        agreeToTerms: false,
      }}
      onSubmit={() => {
        dispatch(agreeToTerms(true));
        navigate(`/${orgSlug}/app/personalInfo`);
      }}
    >
      {({ handleSubmit, values }) => {
        return (
          <>
            <BeigeTile>
              <Header>Welcome</Header>
              <Text>
                Please read the following text before continuing to the
                application:
              </Text>
              <TermsWrapper bgcolor={bgLighter} bordercolor={bgDarker}>
                {appConfig.termsAndConditions}
              </TermsWrapper>
              <CheckboxField
                name="agreeToTerms"
                wrapperstyle={`align-items: center;`}
              >
                <label style={{ marginLeft: '10px' }}>
                  I understand and agree to the above
                </label>
              </CheckboxField>
              <Button
                fillOnMobile={true}
                style={{ marginTop: '20px' }}
                disabled={!values.agreeToTerms}
                type="submit"
                onClick={handleSubmit}
              >
                Continue
              </Button>
            </BeigeTile>
          </>
        );
      }}
    </Formik>
  );
};
