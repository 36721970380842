import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { ResultsEmail } from './ResultsEmail';
import { KnowYourRightsEmail } from './KnowYourRightsEmail';

export const EmailsRoot = () => {
  return (
    <Routes>
      <Route
        path="/results/:emailToken"
        element={<ResultsEmail />}
      />
      <Route
        path="/know-your-rights"
        element={<KnowYourRightsEmail />}
      />
    </Routes>
  );
};
