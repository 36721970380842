import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { Formik } from 'formik';
import {
  OptionsList,
  SelectField,
  TextField,
  YesNoField,
  YesNoSelectField,
} from '../general/FormFields.js';
import { Button } from '../general/Common';
import { validateIncomeAndAssets } from '../../utils/validators.js';
import { BeigeTile, Header, Subheader } from '../general/BeigeTile.js';
import { MAX_DESKTOP_WIDTH } from '../../cssVars.js';
import {
  employmentStatusOptions,
  yesNoOptions,
} from '../../constants/formOptions.js';
import { useNavigate } from 'react-router-dom';
import { TileBackArrow } from '../general/TileBackArrow.js';
import {
  appConfigSelector,
  incomeAndAssetsSelector,
} from '../../selectors/entities.js';
import { DependentsEditor } from './DependentsField.js';
import { useUpdateIncomeAndAssets } from '../../hooks/editors.js';
import { useAdjacentSteps } from '../../hooks/useSteps.js';
import { submitWithErrorMsg } from '../../utils/helpers.js';

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
  margin-bottom: 20px;
  & > div:second-child {
    font-size: 14px;
    font-style: italic;
    margin-right: 10px;
  }
  @media only screen and (max-width: ${MAX_DESKTOP_WIDTH}px) {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    > div {
      margin: 10px 0px;
      max-width: 400px;
    }
    > div:last-child {
      align-self: stretch;
    }
  }
`;

const Text = styled.p`
  margin: 10px 0px;
`;

// First form users sees in the eligibility checker flow, asks for name(s) and DOB
export const IncomeAndAssets = () => {
  const navigate = useNavigate();
  const { nextStep, prevStep } = useAdjacentSteps('incomeAndAssets');
  const appConfig = useSelector(appConfigSelector);
  const incomeAndAssetsState = useSelector(incomeAndAssetsSelector);
  const incomeAndAssetsConfig = appConfig.incomeAndAssets;
  const validate = validateIncomeAndAssets(incomeAndAssetsConfig);

  useEffect(() => {
    // if this page's visibility is set to false, skip to the next page
    if (incomeAndAssetsConfig && incomeAndAssetsConfig.visible === false) {
      navigate(nextStep);
    }
  }, [incomeAndAssetsConfig]);

  const [submit, submitting] = useUpdateIncomeAndAssets(() =>
    navigate(nextStep)
  );

  const getFieldValue = (fieldId, fieldType) =>
    incomeAndAssetsConfig && incomeAndAssetsConfig.fields[fieldId][fieldType];
  const anyFieldInListIsActive = (fieldList) =>
    fieldList.some((fieldId) => getFieldValue(fieldId, 'visible'));

  const initValues = incomeAndAssetsState
    ? {
        ...incomeAndAssetsState,
        dependents:
          incomeAndAssetsState.dependents.length > 0
            ? incomeAndAssetsState.dependents
            : [{ age: '', name: '', relationship: '' }],
      }
    : {
        dependents: [{ age: '', name: '', relationship: '' }],
        employmentStatus: '',
        wages: '',
        wagesHousehold: '',
        unemployment: '',
        snap: '',
        retirement: '',
        publicHousingAssistance: '',
        childSpousalSupport: '',
        ssi: '',
        hasAdditionalVehicle: null,
        vehicleValue: '',
        hasAdditionalHome: null,
        homeValue: '',
        hasOtherAssets: null,
        otherAssetsValue: '',
      };

  return (
    <Formik
      validate={validate}
      initialValues={initValues}
      enableReinitialize={true}
      onSubmit={submit}
    >
      {({ handleSubmit, errors, touched, values, submitCount }) => {
        // return error if field is touched
        const getError = (name) =>
          (touched[name] || submitCount >= 1) && errors[name];
        return (
          <>
            <BeigeTile>
              <Header>
                <TileBackArrow onClick={() => navigate(prevStep)} />
                Income and Assets
              </Header>

              <Text>
                Please fill out the following information to determine if you
                qualify for a waiver of court filing fees. The information will
                be provided in court filings and will be sworn underoath.
              </Text>

              {getFieldValue('dependents', 'visible') && (
                <>
                  <Subheader>Dependents</Subheader>
                  <Text>
                    Dependents are people who live with you and either depend on
                    you for financial support or help support you. Please list
                    the name, age, and relationship to you for each of your
                    dependents.
                  </Text>
                  <DependentsEditor />
                </>
              )}

              {anyFieldInListIsActive([
                'employmentStatus',
                'wages',
                'wagesHousehold',
                'unemployment',
                'snap',
                'publicHousingAssistance',
                'childSpousalSupport',
                'ssi',
              ]) && (
                <>
                  <Subheader>Monthly Household Income</Subheader>
                  <Text>
                    The following questions are related to your total
                    household's monthly income. Please answer to the best of
                    your ability based on your current financial situation.
                  </Text>
                  <Text>
                    Please enter total income for all contributing members of
                    your household. A household consists of the people living
                    with you who depend on you for financial support or are
                    helping to support you.
                  </Text>
                  <Text style={{ marginBottom: '30px' }}>
                    <b>If a field does not apply to you, please enter 0.</b>
                  </Text>
                </>
              )}

              {getFieldValue('employmentStatus', 'visible') && (
                <>
                  <SelectField
                    short
                    label="Applicant's employment status*"
                    name="employmentStatus"
                    error={getError('employmentStatus')}
                  >
                    <OptionsList
                      options={employmentStatusOptions}
                      name="employmentStatus"
                    />
                  </SelectField>
                </>
              )}

              {getFieldValue('wages', 'visible') && (
                <>
                  <TextField
                    short
                    label="Applicant's total monthly work wages*"
                    name="wages"
                    error={getError('wages')}
                  />
                </>
              )}

              {getFieldValue('wagesHousehold', 'visible') && (
                <>
                  <TextField
                    short
                    label="Total monthly work wages of any additional household members listed above*"
                    name="wagesHousehold"
                    error={getError('wagesHousehold')}
                  />
                </>
              )}

              {getFieldValue('unemployment', 'visible') && (
                <>
                  <TextField
                    short
                    helperText="Monthly total of unemployment benefits received by any/all members of the household."
                    label="Unemployment*"
                    name="unemployment"
                    error={getError('unemployment')}
                  />
                </>
              )}

              {getFieldValue('snap', 'visible') && (
                <>
                  <TextField
                    short
                    label="Food Stamps / SNAP*"
                    name="snap"
                    error={getError('snap')}
                  />
                </>
              )}

              {getFieldValue('retirement', 'visible') && (
                <>
                  <TextField
                    short
                    label="Retirement / Pension*"
                    name="retirement"
                    error={getError('retirement')}
                  />
                </>
              )}

              {getFieldValue('publicHousingAssistance', 'visible') && (
                <>
                  <TextField
                    short
                    label="Public Housing Assistance*"
                    name="publicHousingAssistance"
                    helperText="Amount of voucher"
                    error={getError('publicHousingAssistance')}
                  />
                </>
              )}

              {getFieldValue('childSpousalSupport', 'visible') && (
                <>
                  <TextField
                    short
                    label="Child/Spousal Support*"
                    name="childSpousalSupport"
                    error={getError('childSpousalSupport')}
                  />
                </>
              )}

              {getFieldValue('ssi', 'visible') && (
                <>
                  <TextField
                    short
                    label="SSI/SSDI/VA Disability *"
                    name="ssi"
                    error={getError('ssi')}
                  />
                </>
              )}

              {anyFieldInListIsActive([
                'additionalHome',
                'additionalVehicle',
                'otherAssets',
              ]) && (
                <>
                  <Subheader>Assets</Subheader>
                </>
              )}

              {getFieldValue('additionalHome', 'visible') && (
                <>
                  <YesNoSelectField
                    label="Do you own more than one house?*"
                    name="hasAdditionalHome"
                    error={getError('hasAdditionalHome')}
                  />
                  {values.hasAdditionalHome && (
                    <TextField
                      short
                      label="What is the value of that home only, minus any outstanding mortgage?"
                      name="homeValue"
                      error={getError('homeValue')}
                    />
                  )}
                </>
              )}

              {getFieldValue('additionalVehicle', 'visible') && (
                <>
                  <YesNoSelectField
                    label="Do you own more than one vehicle that is paid off and in your name?*"
                    name="hasAdditionalVehicle"
                    error={getError('hasAdditionalVehicle')}
                  />
                  {values.hasAdditionalVehicle && (
                    <TextField
                      short
                      label="If you were to sell your additional vehicle(s) today, how much would it/they be worth?* (If you are still making payments, you do not own the vehicle)"
                      name="vehicleValue"
                      error={getError('vehicleValue')}
                    />
                  )}
                </>
              )}

              {getFieldValue('otherAssets', 'visible') && (
                <>
                  <YesNoSelectField
                    label="Do you have any checking or savings accounts, stocks, bonds, or other property?*"
                    name="hasOtherAssets"
                    error={getError('hasOtherAssets')}
                  />
                  {values.hasOtherAssets && (
                    <TextField
                      short
                      label="What is the current value of your bank accounts, stocks, bonds, or other property?"
                      name="otherAssetsValue"
                      error={getError('otherAssetsValue')}
                    />
                  )}
                </>
              )}
              <div>* These fields are mandatory</div>

              <Footer>
                <Button
                  fillOnMobile={true}
                  type="submit"
                  isLoading={submitting}
                  onClick={submitWithErrorMsg(errors, handleSubmit)}
                >
                  Next
                </Button>
              </Footer>
            </BeigeTile>
          </>
        );
      }}
    </Formik>
  );
};
