import React from 'react';
import styled from 'styled-components';
import { Formik } from 'formik';
import {
  YesNoField,
  CheckboxGroup,
  CheckboxField,
} from '../general/FormFields.js';
import { Button, LinkLikeButton, StyledHR } from '../general/Common';
import {
  askTraffickingVictimQuestionsSelector,
  askVeteranQuestionsSelector,
  otherCriminalHistorySelector,
} from '../../selectors/entities.js';
import { BeigeTile, Header } from '../general/BeigeTile.js';
import { useNavigate, useParams } from 'react-router-dom';
import { disqualifyingOffensesOptions } from '../../constants/check.js';
import { useAdditionalInfo } from '../../hooks/editors.js';
import { useDispatch, useSelector } from 'react-redux';
import {
  setVeteransReemploymentPrgModal,
  setVeteransTreatmentCrtModal,
} from '../../actions/modals.js';
import { useAdjacentSteps } from '../../hooks/useSteps.js';
import { TileBackArrow } from '../general/TileBackArrow.js';
import { ERROR } from '../../cssVars.js';

const Text = styled.p`
  margin: 10px 0px;
`;

const ErrorText = styled.p`
  color: ${ERROR};
  margin: 10px 0px;
`;

// Prompts user for disqualifying history responses (e.g. sex offender registration, serious violent/abusive offenses)
// and some preliminary followup questions depending on their case flags (veteran & human trafficking victim questions)
export const PrelimQuestions = () => {
  const { orgSlug } = useParams();
  const { prevStep } = useAdjacentSteps('criminalHistory');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [submit, submitting] = useAdditionalInfo(() => {
    navigate(`/${orgSlug}/app/criminalHistory/followups/0`);
  });
  const [
    askVeteranQuestions,
    askVetTreatmentCrtQuestions,
    askVetReemploymentPrgQuestions,
  ] = useSelector(askVeteranQuestionsSelector);
  const askTraffickingVictimQuestions = useSelector(
    askTraffickingVictimQuestionsSelector
  );
  const otherCriminalHistoryState = useSelector(otherCriminalHistorySelector);
  const initValues = otherCriminalHistoryState
    ? {
        ...otherCriminalHistoryState,
        noneOfTheAbove:
          otherCriminalHistoryState.disqualifyingOffenses &&
          otherCriminalHistoryState.disqualifyingOffenses.length === 0,
      }
    : {
        sexOffender: null,
        disqualifyingOffenses: [],
        hasCrimeOutsideTexas: null,
        hasCrimeBefore1990: null,
        noneOfTheAbove: false,
        isVeteran: null,
        completedVetReemploymentPrg: null,
        completedVetTreatmentCrt: null,
        hasBeenVictim: null,
        hasVictimCaseInHarrisCounty: null,
        assistedInvestigation: null,
      };

  return (
    <Formik
      initialValues={initValues}
      enableReinitialize={true}
      onSubmit={submit}
    >
      {({ handleSubmit, errors, touched, values, submitCount }) => {
        // return error if field is touched
        const getError = (name) =>
          (touched[name] || submitCount >= 1) && errors[name];

        // Disqualifying offenses / sex offender logic
        const checkedAtLeastOneBox =
          values.noneOfTheAbove || values.disqualifyingOffenses.length > 0;
        const noneOfTheAboveAndOffenseError =
          values.noneOfTheAbove && values.disqualifyingOffenses.length > 0;
        const hasDisqualifyingOffense =
          values.sexOffender === true ||
          values.disqualifyingOffenses.length > 0;
        // Sex offender question and disqualifying offenses checklist are complete
        const disqualifyingOffensesQuestionsComplete =
          values.sexOffender != null &&
          checkedAtLeastOneBox &&
          !noneOfTheAboveAndOffenseError;
        // Other crimes (outside of HC & before 1990) questions are completed (or don't need to be completed bc they are skipped due to a disqualifying offense)
        const otherCrimesQuestionsComplete =
          (hasDisqualifyingOffense ||
            (values.hasCrimeOutsideTexas != null &&
              values.hasCrimeBefore1990 != null)) &&
          !noneOfTheAboveAndOffenseError;

        // Veteran logic
        const vetReemploymentPrgQuestionsComplete =
          !askVetReemploymentPrgQuestions ||
          values.completedVetReemploymentPrg != null;
        const vetTreatmentCrtQuestionsComplete =
          !askVetTreatmentCrtQuestions ||
          values.completedVetTreatmentCrt != null ||
          hasDisqualifyingOffense;
        const veteranPortionComplete =
          values.isVeteran === false ||
          (vetReemploymentPrgQuestionsComplete &&
            vetTreatmentCrtQuestionsComplete);

        // Victim of trafficking / compelling protitution logic, cascading flow
        let traffickingVictimPortionComplete =
          values.hasBeenVictim != null || hasDisqualifyingOffense;
        if (values.hasBeenVictim) {
          traffickingVictimPortionComplete =
            values.hasVictimCaseInHarrisCounty != null;
          if (values.hasVictimCaseInHarrisCounty) {
            traffickingVictimPortionComplete =
              values.investigationAssistanceRequested != null;
            if (values.investigationAssistanceRequested) {
              traffickingVictimPortionComplete =
                values.assistedInvestigation != null;
            }
          }
        }

        // Overall NDO form section (veteran & trafficking victim) completion
        const ndoPrelimQuestionsComplete =
          (!askVeteranQuestions || veteranPortionComplete) &&
          (!askTraffickingVictimQuestions || traffickingVictimPortionComplete);

        return (
          <BeigeTile>
            <Header style={{ marginBottom: '0px' }}>
              <TileBackArrow onClick={() => navigate(prevStep)} />
              Additional Info
            </Header>

            <Text>
              There are some specific situations that may affect your chances
              for eligibility. These followup questions will help us determine
              which nondisclosure forms might work for you.
            </Text>

            <Text>
              Have you ever been required to register as a sex offender?*
            </Text>
            <YesNoField name="sexOffender" error={getError('sexOffender')} />

            {values.sexOffender === false && (
              <>
                <Text id="disqualifyingOffensesLabel">
                  Please take a look at the following offenses. Check the
                  corresponding box if you have <b>ever been convicted</b> or
                  placed on <b>deferred adjudication</b> for that offense, or if
                  your offense does not match any of the provided categories.*
                </Text>
                <CheckboxGroup
                  name="disqualifyingOffenses"
                  labelId="disqualifyingOffensesLabel"
                  error={getError('disqualifyingOffenses')}
                  checkboxOptions={disqualifyingOffensesOptions}
                  style={{ marginLeft: '20px' }}
                  checkboxStyle={{ marginTop: '15px' }}
                />
                <CheckboxField
                  name="noneOfTheAbove"
                  testId="disqOffensesNoneOfTheAbove"
                  error={getError('noneOfTheAbove')}
                  wrapperStyle={`margin: 15px 0px 30px 20px;`}
                >
                  None of the above
                </CheckboxField>
                {noneOfTheAboveAndOffenseError && (
                  <ErrorText>
                    You cannot select "None of the above" and{' '}
                    {values.disqualifyingOffenses.length > 1
                      ? 'offenses'
                      : 'an offense'}{' '}
                    at the same time. Please review and edit your selection.
                  </ErrorText>
                )}
              </>
            )}

            {/* Show helper message if the user hasn't checked any boxes in list */}
            {values.sexOffender === false && !checkedAtLeastOneBox && (
              <div style={{ fontSize: '18px' }}>
                <i>
                  *You must check at least one offense or "None of the above" to
                  continue
                </i>
              </div>
            )}

            {!hasDisqualifyingOffense &&
              disqualifyingOffensesQuestionsComplete && (
                <>
                  <StyledHR />
                  <Text>
                    Have you been arrested for a crime{' '}
                    <b>before January 1st, 1990?</b>*
                  </Text>
                  <YesNoField
                    name="hasCrimeBefore1990"
                    error={getError('hasCrimeBefore1990')}
                  />

                  <Text>
                    Have you ever been arrested for a crime{' '}
                    <b>outside of Harris County?</b>*
                  </Text>
                  <YesNoField
                    name="hasCrimeOutsideTexas"
                    error={getError('hasCrimeOutsideTexas')}
                  />
                </>
              )}

            {/* Once the disqualifying offenses portion is complete, move on to veteran section */}
            {/* Ask about veteran's reemployment program if they are potentially eligible, even if they have a disqualifying offense */}
            {otherCrimesQuestionsComplete &&
              askVeteranQuestions &&
              (!hasDisqualifyingOffense || askVetReemploymentPrgQuestions) && (
                <>
                  <StyledHR />
                  <Text>
                    Are you a <b>veteran</b> of the United States Armed Forces?
                    (including a member of the reserves, national guard, or
                    state guard)*
                  </Text>
                  <YesNoField name="isVeteran" error={getError('isVeteran')} />

                  {values.isVeteran && askVetReemploymentPrgQuestions && (
                    <>
                      <Text>
                        Have you ever completed a{' '}
                        <b>Veterans Reemployment Program</b> in Harris County?*
                        {` `}
                        <LinkLikeButton
                          onClick={() =>
                            dispatch(setVeteransReemploymentPrgModal(true))
                          }
                          style={{ fontWeight: '600', fontStyle: 'italic ' }}
                        >
                          (what does this mean?)
                        </LinkLikeButton>
                      </Text>
                      <YesNoField
                        name="completedVetReemploymentPrg"
                        error={getError('completedVetReemploymentPrg')}
                      />
                    </>
                  )}
                </>
              )}

            {/* ONLY IF they do not have any disqualifying offenses, show vet treatment court & victim of human trafficking questions */}
            {!hasDisqualifyingOffense && otherCrimesQuestionsComplete && (
              <>
                {values.isVeteran && askVetTreatmentCrtQuestions && (
                  <>
                    <Text>
                      Have you ever completed a <b>Veterans Treatment Court</b>{' '}
                      program in Harris County?*{` `}
                      <LinkLikeButton
                        onClick={() =>
                          dispatch(setVeteransTreatmentCrtModal(true))
                        }
                        style={{ fontWeight: '600', fontStyle: 'italic ' }}
                      >
                        (what does this mean?)
                      </LinkLikeButton>
                    </Text>
                    <YesNoField
                      name="completedVetTreatmentCrt"
                      error={getError('completedVetTreatmentCrt')}
                    />
                  </>
                )}

                {askTraffickingVictimQuestions &&
                  (veteranPortionComplete || !askVeteranQuestions) && (
                    <>
                      <StyledHR />
                      <Text>
                        Have you ever been a victim of human trafficking or
                        compelling prostitution? If you have ever been forced to
                        commit acts of prostitution, this applies to you.
                      </Text>
                      <YesNoField
                        name="hasBeenVictim"
                        error={getError('hasBeenVictim')}
                      />

                      {values.hasBeenVictim === true && (
                        <>
                          <Text>
                            Have you committed any offenses in Harris County
                            solely as the victim of human trafficking or
                            compelling prostitution?
                          </Text>
                          <YesNoField
                            name="hasVictimCaseInHarrisCounty"
                            error={getError('hasVictimCaseInHarrisCounty')}
                          />
                        </>
                      )}

                      {values.hasBeenVictim === true &&
                        values.hasVictimCaseInHarrisCounty === true && (
                          <>
                            <Text>
                              Has law enforcement or the prosecuting attorney
                              requested assistance in the investigation or
                              prosecution of an offense involving human
                              trafficking, compelling prostitution, or other
                              similar federal offense?
                            </Text>
                            <YesNoField
                              name="investigationAssistanceRequested"
                              error={getError(
                                'investigationAssistanceRequested'
                              )}
                            />
                          </>
                        )}

                      {values.hasBeenVictim === true &&
                        values.hasVictimCaseInHarrisCounty === true &&
                        values.investigationAssistanceRequested === true && (
                          <>
                            <Text>
                              Did you provide assistance to the law enforcement
                              or the prosecuting attorney, or were you unable to
                              provide assistance due to your age or a physical
                              or mental disability resulting from being a victim
                              of an offense involving human trafficking,
                              compelling prostitution, or other similar federal
                              offense?
                            </Text>
                            <YesNoField
                              name="assistedInvestigation"
                              error={getError('assistedInvestigation')}
                            />
                          </>
                        )}
                    </>
                  )}
              </>
            )}

            {otherCrimesQuestionsComplete &&
              ((hasDisqualifyingOffense &&
                vetReemploymentPrgQuestionsComplete) ||
                ndoPrelimQuestionsComplete) && (
                <Button
                  type="submit"
                  fillOnMobile={true}
                  isLoading={submitting}
                  style={{ minWidth: '260px', marginTop: '20px' }}
                  onClick={handleSubmit}
                >
                  Next
                </Button>
              )}
          </BeigeTile>
        );
      }}
    </Formik>
  );
};
