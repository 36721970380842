import React from 'react';
import { Provider } from 'react-redux';
import { Provider as ReduxQueryProvider } from 'redux-query-react';
import { BrowserRouter } from 'react-router-dom';
import store from './reducers/store';
import { RootRoutes } from './components/Routes'
import ScrollToTop from './components/general/ScrollToTop';
import PauseScrollWhenModalIsOpen from './components/general/PauseScrollWhenModalIsOpen';
import 'react-toastify/dist/ReactToastify.css';

const App = () => (
  <Provider store={store}>
    <ReduxQueryProvider queriesSelector={(state) => state.queries}>
      <BrowserRouter>
        <ScrollToTop />
        <PauseScrollWhenModalIsOpen />
        <RootRoutes />
      </BrowserRouter>
    </ReduxQueryProvider>
  </Provider>
);

export default App;