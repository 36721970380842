import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Navigate,
  Route,
  Routes,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { useRequest } from 'redux-query-react';
import { getConfigQueryAdmin } from '../../actions/queries';
import { sessionExpirationTimeSelector } from '../../selectors/entities';
import { statusIsGood } from '../../utils/helpers';
import { AdminHeader } from '../general/Header';
import { PageLoader } from '../general/Loaders';
import SessionExpirationTimer from '../general/SessionExpirationTimer';
import { ConfigEditor } from './ConfigEditor';
import { ExportPage } from './ExportPage';
import { LookupBySPNPage } from './LookupBySPNPage';
import { LookupPage } from './LookupPage';
import { SettingsEditor } from './SettingsEditor';
import { UsersPage } from './UsersPage';

// Root component for all of the admin pages.
// Redirects if token is invalid or does not exist
export const AdminRoot = () => {
  const { orgSlug } = useParams();
  const navigate = useNavigate();
  const sessionExpirationTime = useSelector(sessionExpirationTimeSelector);
  const [{ isFinished, status }, refresh] = useRequest(
    getConfigQueryAdmin(orgSlug)
  );

  useEffect(() => {
    if (
      status != null &&
      status > 0 &&
      !statusIsGood(status) &&
      sessionExpirationTime
    ) {
      refresh();
    }
    // eslint-disable-next-line
  }, [status, sessionExpirationTime]);

  if (
    status != null &&
    status > 0 &&
    !statusIsGood(status) &&
    !sessionExpirationTime
  ) {
    return <Navigate to={`/${orgSlug}/login`} replace />;
  }

  if (!isFinished) {
    return <PageLoader />;
  }

  return (
    <div style={{ backgroundColor: '#fff', minHeight: '100vh' }}>
      <AdminHeader />
      <div style={{ padding: '20px 40px' }}>
        <Routes>
          <Route path="/configs" element={<ConfigEditor />} />
          <Route path="/settings" element={<SettingsEditor />} />
          <Route path="/export" element={<ExportPage />} />
          <Route path="/users" element={<UsersPage />} />
          <Route path="/quick-lookup-spn" element={<LookupBySPNPage />} />
          <Route path="/quick-lookup" element={<LookupPage />} />
          <Route
            path="*"
            element={<Navigate to='settings' replace />}
          />
        </Routes>
      </div>
      <SessionExpirationTimer />
    </div>
  );
};
