import React, { useState } from 'react';
import styled from 'styled-components';
import { TextField } from '../general/FormFields';
import { useColors } from '../../hooks/useColors';
import { SettingsHeader } from './SettingsEditor';
import { useFormikContext } from 'formik';
import { Button, ButtonLikeLink, TextLink } from '../general/Common';
import { isValidHexcode } from '../../utils/helpers';
import { useImageUploadQuery } from '../../hooks/editors';
import { useSelector } from 'react-redux';
import { orgInfoSelector } from '../../selectors/entities';
import { useParams } from 'react-router';
import websiteIcon from '../../assets/website.svg'

const PreviewContainer = styled.div`
  display: flex;
  display: space-around;
  > div:first-child {
    border-radius: 6px 0px 0px 6px;
  }
  > div:last-child {
    border-radius: 0px 6px 6px 0px;
  }
`

const PreviewPortion = styled.div`
  height: 40px;
  flex-grow: 1;
  background-color: ${({ color }) => color};
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-weight: 700;
`

const Subheader = styled.p`
  font-weight: 700;
  margin: 10px 0px;
  font-size: 19px;
  margin-top: 20px;
`

const Description = styled.p`
  margin-top: 10px;
`

const CustomFileInput = styled.label`
  display: inline-block;
  padding: 10px 25px;
  margin-right: 10px;
  cursor: pointer;
  border-radius: 4px;
  color: #fff;
  background-color: ${({primary}) => primary};
  font-weight: 700;
`

const LogoPreviewWrapper = styled.div`
  width: 300px;
  height: 100px;
  background-color: #F1F1F1;
  border: 2px dotted lightgrey;
  border-radius: 4px;
  margin: 10px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: grey;
`;

const LogoPreview = styled.img`
  max-width: 300px;
  max-height: 100px
`

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

const WebsiteIcon = styled.img`
  width: 20px;
  height: 20px;
  position: relative;
  top: 4px;
  margin-left: 8px;
`

const PreviewBar = ({ colorToPreview }) => {
  const { primary, primaryDark, bgColor, bgDarker } = useColors(colorToPreview);
  return (
    <PreviewContainer>
      <PreviewPortion color={primary}>
        Theme Preview
      </PreviewPortion>
      <PreviewPortion color={primaryDark} />
      <PreviewPortion color={bgDarker} />
      <PreviewPortion color={bgColor} />
    </PreviewContainer>
  )
}

function getExtension(filename) {
  var parts = filename.split('.');
  return parts[parts.length - 1];
}

function isImage(filename) {
  var ext = getExtension(filename);
  switch (ext.toLowerCase()) {
    case 'jpg':
    case 'jpg':
    case 'png':
    case 'svg':
      return true;
  }
  return false;
}

// https://www.geeksforgeeks.org/file-uploading-in-react-js/
export const GeneralEditor = () => {
  const { values, errors } = useFormikContext();
  const { orgSlug } = useParams();
  const orgInfo = useSelector(orgInfoSelector);
  const [imgFile, setImgFile] = useState(null);
  const [fileError, setFileError] = useState(null);
  const { primary } = useColors();
  const isValidInput = isValidHexcode(values.themeColor);

  const logoUrl = imgFile && typeof imgFile.name == 'string' 
          ? URL.createObjectURL(imgFile) 
          : orgInfo.logoUrl

  const [submit, submitting] = useImageUploadQuery(() => setImgFile(null));

  const onFileChange = (e) => {
    const targetFile = e.target.files[0];
    if (targetFile && targetFile.size > 100000) {
      setFileError('Please choose a smaller image (under 100KB)');
    } else if (targetFile && !isImage(targetFile.name)) {
      setFileError('Must be a PNG, JPEG, or SVG image.')
    } else {
      setFileError(null);
      setImgFile(targetFile);
    }
  }
  
  return (
    <div>
      <HeaderWrapper>
        <SettingsHeader style={{marginBottom: '0px'}} id="general">
          General
        </SettingsHeader>
        <ButtonLikeLink to={`/${orgSlug}`}>
          View Site
          <WebsiteIcon src={websiteIcon} alt="website" />
        </ButtonLikeLink>
      </HeaderWrapper>
      {/* <Subheader>Organization name</Subheader>
      <Description>Name that will be displayed to users on the landing page of the application.</Description>
      <TextField short name="name" errors={errors.name} /> */}
      <Subheader>Site color</Subheader>
      <Description>Enter a bold color in <TextLink to="https://htmlcolorcodes.com/">Hexcode format</TextLink> to be used as the base for the site theme. </Description>
      <TextField short error={errors.themeColor} placeholder="#11AABB" name="themeColor" />
      <PreviewBar colorToPreview={!isValidInput ? null : values.themeColor} />

      <Subheader>Logo</Subheader>
      <Description>This logo will be displayed in the top-left corner of the application. For best results, choose an image with a transparent background.</Description>
      <LogoPreviewWrapper>
        {logoUrl != null
          ? <LogoPreview src={logoUrl}/>
          : <div>No logo uploaded</div>
        }
      </LogoPreviewWrapper>
      {fileError && <div style={{ color: '#AD2424', marginBottom: '5px' }}>{fileError}</div>}
      <CustomFileInput primary={primary}>
          <input type="file" onChange={onFileChange} />
          Upload {logoUrl ? 'New' : ''} Logo
      </CustomFileInput>
      <Button disabled={logoUrl === orgInfo.logoUrl} 
              style={{ minWidth: '100px'}}
              onClick={() => submit(imgFile)} isLoading={submitting}>
          Save Logo
      </Button>
    </div>
  )
}