import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Formik } from 'formik';
import { TextField } from '../general/FormFields.js';
import { Button } from '../general/Common';
import { validateExpenses } from '../../utils/validators.js';
import { BeigeTile, Header, Subheader } from '../general/BeigeTile.js';
import { MAX_DESKTOP_WIDTH } from '../../cssVars.js';
import { useNavigate, useParams } from 'react-router-dom';
import { TileBackArrow } from '../general/TileBackArrow.js';
import { useSelector } from 'react-redux';
import {
  appConfigSelector,
  expensesSelector,
} from '../../selectors/entities.js';
import { useUpdateExpenses } from '../../hooks/editors.js';
import { useAdjacentSteps } from '../../hooks/useSteps.js';
import { submitWithErrorMsg } from '../../utils/helpers.js';

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
  margin-bottom: 20px;
  & > div:second-child {
    font-size: 14px;
    font-style: italic;
    margin-right: 10px;
  }
  @media only screen and (max-width: ${MAX_DESKTOP_WIDTH}px) {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    > div {
      margin: 10px 0px;
      max-width: 400px;
    }
    > div:last-child {
      align-self: stretch;
    }
  }
`;

const Text = styled.p`
  margin: 10px 0px;
`;

// First form users sees in the eligibility checker flow, asks for name(s) and DOB
export const Expenses = () => {
  const navigate = useNavigate();
  const { nextStep, prevStep } = useAdjacentSteps('expenses');
  const appConfig = useSelector(appConfigSelector);
  const expensesState = useSelector(expensesSelector);
  const sectionConfig = appConfig.expenses;
  const validate = validateExpenses(sectionConfig);

  useEffect(() => {
    // if this page's visibility is set to false, skip to the next page
    if (sectionConfig && sectionConfig.visible === false) {
      navigate(nextStep);
    }
  }, [sectionConfig]);

  const [submit, submitting] = useUpdateExpenses(() => {
    navigate(nextStep);
  });

  const getFieldValue = (fieldId, fieldType) =>
    sectionConfig && sectionConfig.fields[fieldId][fieldType];
  const anyFieldInListIsActive = (fieldList) =>
    fieldList.some((fieldId) => getFieldValue(fieldId, 'visible'));

  const initValues = expensesState
    ? { ...expensesState }
    : {
        rent: '',
        housingInfo: '',
        utilities: '',
        food: '',
        childCare: '',
        childSpousalSupport: '',
        automobileExpenses: '',
        transportationExpenses: '',
        attorneyFees: '',
        insurance: '',
        medical: '',
        laundry: '',
        misc: '',
        otherExpensesInfo: '',
      };

  return (
    <Formik
      validate={validate}
      initialValues={initValues}
      enableReinitialize={true}
      onSubmit={submit}
    >
      {({ handleSubmit, errors, touched, values, submitCount }) => {
        // return error if field is touched
        const getError = (name) =>
          (touched[name] || submitCount >= 1) && errors[name];
        return (
          <>
            <BeigeTile>
              <Header>
                <TileBackArrow onClick={() => navigate(prevStep)} />
                Expenses
              </Header>
              <Text>
                The following questions are related to your total household's
                monthly expenses. Please answer to the best of your ability
                based on your current financial situation.
              </Text>

              <Subheader>Monthly Expenses</Subheader>
              <Text>
                Please enter total expenses for all contributing members of your
                household. A household consists of the people living with you
                who depend on you for financial support or are helping to
                support you.
              </Text>
              <Text style={{ marginBottom: '30px' }}>
                <b>If a field does not apply to you, please enter 0.</b>
              </Text>

              {getFieldValue('rent', 'visible') && (
                <>
                  <TextField
                    short
                    label="Mortgage/Rent*"
                    helperText="If you receive a housing voucher, list the total amount of your rent including the voucher, not just the amount you are responsible for each month."
                    name="rent"
                    error={getError('rent')}
                  />
                </>
              )}

              {getFieldValue('housingInfo', 'visible') && (
                <>
                  <TextField
                    label="If you are not in control of your housing situation (not your lease, staying with friends/family, couch surfing, homeless, in danger of losing housing), please explain your living situation."
                    name="housingInfo"
                    error={getError('housingInfo')}
                  />
                </>
              )}

              {getFieldValue('utilities', 'visible') && (
                <>
                  <TextField
                    short
                    label="Utilities*"
                    name="utilities"
                    error={getError('utilities')}
                    helperText="Phone, internet, cable, water, gas, electricity."
                  />
                </>
              )}

              {getFieldValue('food', 'visible') && (
                <>
                  <TextField
                    short
                    label="Food*"
                    name="food"
                    error={getError('food')}
                    helperText="If you receive SNAP benefits, indicate the total amount you typically spend on food per month including the SNAP benefits you receive."
                  />
                </>
              )}

              {getFieldValue('childCare', 'visible') && (
                <>
                  <TextField
                    short
                    label="Child Care*"
                    name="childCare"
                    error={getError('childCare')}
                  />
                </>
              )}

              {getFieldValue('childSpousalSupport', 'visible') && (
                <>
                  <TextField
                    short
                    label="Child/Spousal Support*"
                    name="childSpousalSupport"
                    error={getError('childSpousalSupport')}
                    helperText="Support payments paid by you on a monthly basis.  If you owe child or spousal support that you are not currently paying, enter $0.00."
                  />
                </>
              )}

              {getFieldValue('automobileExpenses', 'visible') && (
                <>
                  <TextField
                    short
                    label="Car Payment/Auto Insurance*"
                    name="automobileExpenses"
                    error={getError('automobileExpenses')}
                  />
                </>
              )}

              {getFieldValue('transportationExpenses', 'visible') && (
                <>
                  <TextField
                    short
                    label="Gas/Bus Fare/Tolls*"
                    name="transportationExpenses"
                    error={getError('transportationExpenses')}
                  />
                </>
              )}

              {getFieldValue('attorneyFees', 'visible') && (
                <>
                  <TextField
                    short
                    label="Attorney's Fees*"
                    name="attorneyFees"
                    error={getError('attorneyFees')}
                  />
                </>
              )}

              {getFieldValue('insurance', 'visible') && (
                <>
                  <TextField
                    short
                    label="Health/Life Insurance Premiums*"
                    name="insurance"
                    error={getError('insurance')}
                    helperText="Out of pocket premiums paid by you on a monthly basis."
                  />
                </>
              )}

              {getFieldValue('medical', 'visible') && (
                <>
                  <TextField
                    short
                    label="Medical Expenses*"
                    name="medical"
                    error={getError('medical')}
                    helperText="Co-pays, prescriptions, ongoing out of pocket expenses, etc."
                  />
                </>
              )}

              {getFieldValue('laundry', 'visible') && (
                <>
                  <TextField
                    short
                    label="Clothing/Laundry*"
                    name="laundry"
                    error={getError('laundry')}
                  />
                </>
              )}

              {getFieldValue('misc', 'visible') && (
                <>
                  <TextField
                    short
                    label="Other Misc. Personal Expenses*"
                    name="misc"
                    error={getError('misc')}
                    helperText="Any other expenses that you or your household has that have not already been addressed above."
                  />
                </>
              )}

              {getFieldValue('otherExpensesInfo', 'visible') && (
                <>
                  <TextField
                    label="If your expenses exceed your income, how do you make ends meet? Do you receive financial assistance from friends or family? Are bills left unpaid? Please explain."
                    name="otherExpensesInfo"
                    error={getError('otherExpensesInfo')}
                  />
                </>
              )}
              <div>* These fields are mandatory</div>

              <Footer>
                <Button
                  fillOnMobile={true}
                  type="submit"
                  isLoading={submitting}
                  onClick={submitWithErrorMsg(errors, handleSubmit)}
                >
                  Next
                </Button>
              </Footer>
            </BeigeTile>
          </>
        );
      }}
    </Formik>
  );
};
