import { Formik, useFormikContext } from 'formik';
import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Navigate,
  Route,
  Routes,
  useNavigate,
  useParams,
} from 'react-router-dom';
import styled from 'styled-components';
import { setCase } from '../../actions/modals.js';
import {
  BAC_UNDER_015,
  COMMITTED_AFTER_SUBMITTING_PETITION,
  COMMITTED_SOLELY_AS_TRAFFICKING_VICTIM,
  COMPLETED_VET_REEMPLOYMENT_PRG_FOR_CASE,
  COMPLETED_VET_TREATMENT_COURT_FOR_CASE,
  FINAL_POT_ELIG_411_0731,
  FINAL_POT_ELIG_411_0736,
  FORM_ID_411_0726,
  FORM_ID_411_0727,
  FORM_ID_411_0728,
  FORM_ID_411_0729,
  FORM_ID_411_0731,
  FORM_ID_411_0736,
  HAD_COMMERCIAL_LICENSE_OR_PERMIT,
  INIT_FOLLOWUP_FIELDS_BY_FORM,
  USED_IGNITION_INTERLOCK,
} from '../../constants/cases.js';
import { useCaseFollowupQuestions } from '../../hooks/editors.js';
import { followUpQuestionCasesSelector } from '../../selectors/entities.js';
import { getFormsNeededForFollowUp } from '../../utils/cases.js';
import { validateFollowupQuestions } from '../../utils/validators.js';
import { BeigeTile, Header } from '../general/BeigeTile.js';
import { CaseCard } from '../general/CaseCard.js';
import { BackArrowButton, Button } from '../general/Common';
import { YesNoField } from '../general/FormFields.js';

const Text = styled.p`
  margin: 10px 0px;
`;

// Router component for the case-specific followup question pages
// (where the user walks through each case we need to ask followup questions about)
export const CaseFollowupRoutes = () => {
  const casesToVerify = useSelector(followUpQuestionCasesSelector);

  if (casesToVerify.length === 0) {
    return <Navigate to="../eligibility" replace />;
  }

  return (
    <Routes>
      {casesToVerify.map((currCase, index) => (
        <Route
          path={`${index}`}
          key={currCase.casID}
          element={ <CaseFollowups index={index} />}
        />
      ))}
    </Routes>
  );
};

// Case-specific page component to ask the user NDO form followup questions
export const CaseFollowups = ({ index }) => {
  const { orgSlug } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const casesToVerify = useSelector(followUpQuestionCasesSelector);
  const currCase = casesToVerify[index];
  const clickCase = () => dispatch(setCase(currCase));
  const formIds = getFormsNeededForFollowUp(currCase);

  // Grab the initial values for the form based on the current case
  const initialValues = useMemo(
    () =>
      formIds.reduce(
        (allFields, formId) => ({
          ...allFields,
          ...INIT_FOLLOWUP_FIELDS_BY_FORM[formId],
          ...currCase,
        }),
        {}
      ),
    [currCase, formIds]
  );

  // Go to next case after they submit if there are any cases left; otherwise, go to results
  const nextLink =
    index + 1 < casesToVerify.length
      ? `/${orgSlug}/app/criminalHistory/followups/${index + 1}`
      : `/${orgSlug}/app/eligibility`;
  const [submit, submitting] = useCaseFollowupQuestions(
    () => navigate(nextLink),
    currCase
  );

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      validate={validateFollowupQuestions(currCase)}
      onSubmit={submit}
    >
      {({ handleSubmit, errors, touched, submitCount }) => {
        const getError = (name) =>
          (touched[name] || submitCount >= 1) && errors[name];
        return (
          <BeigeTile>
            {index !== 0 && <BackArrowButton onClick={() => navigate(-1)} />}
            {casesToVerify.length > 1 ? (
              <Header
                style={{ marginBottom: '0px' }}
              >{`Case Verifications (Case ${index + 1} of ${
                casesToVerify.length
              })`}</Header>
            ) : (
              <Header style={{ marginBottom: '0px' }}>Case Verification</Header>
            )}

            <Text>Please take a look at the following case:</Text>
            <CaseCard caseInfo={currCase} onClick={clickCase} />

            {/* Inject form-specific questions for this case */}
            {formIds.map((formId) =>
              getFieldsByFormId(formId, getError, currCase)
            )}

            <Button
              type="submit"
              data-testid="case_followup_next"
              isLoading={submitting}
              fillOnMobile={true}
              style={{ minWidth: '260px' }}
              onClick={handleSubmit}
            >
              Next
            </Button>
          </BeigeTile>
        );
      }}
    </Formik>
  );
};

// Grab the correct question form fields for the given form ID
const getFieldsByFormId = (formId, getError, currCase) => {
  if (formId === FORM_ID_411_0727) {
    return <VetsTreatmentCourtFields key={formId} getError={getError} />;
  }
  if (formId === FORM_ID_411_0728) {
    return <HumanTraffickingVictimFields key={formId} getError={getError} />;
  }
  if (formId === FORM_ID_411_0729) {
    return <VetsReemploymentPrgFields key={formId} getError={getError} />;
  }
  if (formId === FORM_ID_411_0731 || formId === FORM_ID_411_0736) {
    return (
      <DWIConvictionFields
        currCase={currCase}
        key={formId}
        getError={getError}
      />
    );
  }
  if (formId === FORM_ID_411_0726) {
    return <DWIDeferredAdjudictionFields key={formId} getError={getError} />;
  }
};

// Form fields for human trafficking victim eligible cases
const HumanTraffickingVictimFields = ({ getError }) => {
  const { values } = useFormikContext();
  return (
    <>
      <Text style={{ marginTop: '30px' }}>
        Was this offense commited{' '}
        <b>
          solely as the victim of human trafficking or compelling prostitution
        </b>
        ?
      </Text>
      <YesNoField
        name={COMMITTED_SOLELY_AS_TRAFFICKING_VICTIM}
        error={getError(COMMITTED_SOLELY_AS_TRAFFICKING_VICTIM)}
      />
      {values[COMMITTED_SOLELY_AS_TRAFFICKING_VICTIM] === true && (
        <>
          <Text>
            Did you commit this offense after submitting a petition for
            nondisclosure for a related offense? (If you have not submitted a
            petition for nondisclosure for a related offense, please select{' '}
            <b>No</b>)
          </Text>
          <YesNoField
            name={COMMITTED_AFTER_SUBMITTING_PETITION}
            error={getError(COMMITTED_AFTER_SUBMITTING_PETITION)}
          />
        </>
      )}
    </>
  );
};

// Form fields for DWI conviction cases
const DWIConvictionFields = ({ getError, currCase }) => {
  const { values } = useFormikContext();
  return (
    <>
      <Text style={{ marginTop: '30px' }}>
        Was your <b>blood alcohol concentration</b> (BAC) level less than{' '}
        <b>0.15</b> at the time of this offense?
      </Text>
      <YesNoField name={BAC_UNDER_015} error={getError(BAC_UNDER_015)} />
      {/* Ask about the ignition interlock if they are flagged as potentially eligible and have a qualifying BAC */}
      {(currCase[FINAL_POT_ELIG_411_0731] ||
        currCase[FINAL_POT_ELIG_411_0736]) &&
        values[BAC_UNDER_015] === true && (
          <>
            <Text>
              Did you utilize an ignition interlock device on your vehicle for
              at least 6 months?
            </Text>
            <YesNoField
              name={USED_IGNITION_INTERLOCK}
              error={getError(USED_IGNITION_INTERLOCK)}
            />
          </>
        )}
    </>
  );
};

// Form fields for DWI deferred adjudication cases
const DWIDeferredAdjudictionFields = ({ getError }) => {
  const { values } = useFormikContext();
  return (
    <>
      <Text style={{ marginTop: '30px' }}>
        Was your <b>blood alcohol concentration</b> (BAC) level less than{' '}
        <b>0.15</b> at the time of this offense?
      </Text>
      <YesNoField name={BAC_UNDER_015} error={getError(BAC_UNDER_015)} />
      {values[BAC_UNDER_015] === true && (
        <>
          <Text>
            Did you hold a <b>commercial</b> driver's license or a{' '}
            <b>commercial</b> learner's permit at the time of this offense?
          </Text>
          <YesNoField
            name={HAD_COMMERCIAL_LICENSE_OR_PERMIT}
            error={getError(HAD_COMMERCIAL_LICENSE_OR_PERMIT)}
          />
        </>
      )}
    </>
  );
};

// Form fields for veteran's reemployment program applicable cases
const VetsReemploymentPrgFields = ({ getError }) => {
  return (
    <>
      <Text style={{ marginTop: '30px' }}>
        Did you complete a <b>Veterans Reemployment Program</b> for this case?
      </Text>
      <YesNoField
        name={COMPLETED_VET_REEMPLOYMENT_PRG_FOR_CASE}
        error={getError(COMPLETED_VET_REEMPLOYMENT_PRG_FOR_CASE)}
      />
    </>
  );
};

// Form fields for veteran's treatment court applicable cases
const VetsTreatmentCourtFields = ({ getError }) => {
  const { values } = useFormikContext();
  return (
    <>
      <Text style={{ marginTop: '30px' }}>
        Did you complete a <b>Veterans Treatment Court program</b> for this
        case?
      </Text>
      <YesNoField
        name={COMPLETED_VET_TREATMENT_COURT_FOR_CASE}
        error={getError(COMPLETED_VET_TREATMENT_COURT_FOR_CASE)}
      />
    </>
  );
};
